import Head from 'next/head';
import React, { useEffect } from 'react';
import type { ReactElement } from 'react';
import { Box, Flex, Image, Spinner, Text } from '@chakra-ui/react';

import { getPageTitle } from '../config';
import { UserProfile, UserProvider, useUser } from '@auth0/nextjs-auth0/client';
import { useRouter } from 'next/router';

const HomePage = () => {
  const router = useRouter();
  const {
    user: auth0User,
    isLoading: auth0UserIsLoading,
  }: { user?: UserProfile; isLoading: boolean } = useUser();

  useEffect(() => {
    if (!auth0UserIsLoading) {
      router.push(auth0User ? '/homes' : '/login');
    }
  }, [auth0UserIsLoading, auth0User]);

  return (
    <>
      <Head>
        <title>{getPageTitle('Home')}</title>
      </Head>

      <Box bg="gray.610">
        <Flex
          h="100vh"
          w="100vw"
          alignItems="center"
          justifyContent="center"
          flexDirection="row"
        >
          <Flex flexDirection="column" alignItems="center">
            <Image
              src="/platflow-logo.svg"
              alt="Platflow Health"
              width="224px"
              height="52px"
            />
            <Text mt="1.5rem" fontSize="xl" fontWeight="normal">
              Modern ai for skilled nursing
            </Text>
            <Flex w="full" alignItems="center" justifyContent="center">
              <Spinner
                mt={10}
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.10"
                size="xl"
              />
            </Flex>
          </Flex>
        </Flex>
        <Box
          w="100%"
          h="100%"
          bgImg="/bg-login.png"
          bgSize="100%"
          backgroundPosition="center bottom"
          backgroundRepeat="no-repeat"
        />
      </Box>
    </>
  );
};

HomePage.getLayout = function getLayout(page: ReactElement) {
  return <UserProvider>{page}</UserProvider>;
};

export default HomePage;
